import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/wenke/wenke/src/components/layout.js";
import { Container } from '../components/container';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
      <h1>{`DATEN`}{`​`}{`SCHUTZ`}{`​`}{`HINWEISE`}</h1>
      <h3>{`ALLGEMEINE HINWEISE`}</h3>
      <p>{`Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren
personenbezogenen Daten passiert, wenn Sie unsere Website besuchen.
Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert
werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie
unserer unter diesem Text aufgeführten Datenschutzerklärung.`}</p>
      <p><strong parentName="p">{`Wer ist verantwortlich für die Datenerfassung auf dieser Website?`}</strong><br parentName="p"></br>{`
`}{`Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Die
Kontaktdaten können Sie dem Impressum dieser Website entnehmen.`}</p>
      <p><strong parentName="p">{`Wie erfassen wir Ihre Daten?`}</strong><br parentName="p"></br>{`
`}{`Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei
kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
Andere Daten werden automatisch beim Besuch der Website durch unsere IT-
Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser,
Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
automatisch, sobald Sie unsere Website betreten.`}</p>
      <p><strong parentName="p">{`Wofür nutzen wir Ihre Daten?`}</strong><br parentName="p"></br>{`
`}{`Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu
gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet
werden.`}</p>
      <p><strong parentName="p">{`Welche Rechte haben Sie bezüglich Ihrer Daten?`}</strong><br parentName="p"></br>{`
`}{`Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und
Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben
außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu
verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung
der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Details hierzu
entnehmen Sie der Datenschutzerklärung unter „Recht auf Einschränkung der
Verarbeitung“.`}</p>
      <h3>{`ANALYSE-TOOLS UND TOOLS VON DRITTANBIETERN`}</h3>
      <p>{`Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet
werden. Das geschieht vor allem mit Cookies und mit sogenannten
Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym;
das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden.
Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung
bestimmter Tools verhindern. Detaillierte Informationen zu diesen Tools und über
Ihre Widerspruchsmöglichkeiten finden Sie in der folgenden Datenschutzerklärung.
2. Allgemeine Hinweise und Pflichtinformationen`}</p>
      <h3>{`DATENSCHUTZ`}</h3>
      <p>{`Die Betreiber dieser Seite nehmen den Schutz Ihrer persönlichen Daten sehr ernst.
Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der
gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzhinweise.
Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten
erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert
werden können. Die vorliegenden Datenschutzhinweise erläutern, welche Daten wir
erheben und wofür wir sie nutzen. Sie erläutern auch, wie und zu welchem Zweck
das geschieht.
Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der
Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser
Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.`}</p>
      <h3>{`HINWEIS ZUR VERANTWORTLICHEN`}</h3>
      <p>{`Die Verantwortliche für die Datenverarbeitung auf dieser Website ist:
Wenke Reichert
Im Willstein 33
57319 Bad Berleburg
Verantwortliche ist die natürliche oder juristische Person, die allein oder gemeinsam
mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen
Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.`}</p>
      <h3>{`WIDERRUF IHRER EINWILLIGUNG ZUR DATENVERARBEITUNG`}</h3>
      <p>{`Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung
möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu
reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum
Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.`}</p>
      <p>{`Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
gegen Direktwerbung (Art. 21 DSGVO)
Wenn die Datenverarbeitung auf Grundlage von Art. 6 Abs. 1 lit. e oder f DSGVO
erfolgt, haben Sie jederzeit das Recht, aus Gründen, die sich aus Ihrer besonderen
Situation ergeben, gegen die Verarbeitung Ihrer personenbezogenen Daten
Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes
Profiling. Die jeweilige Rechtsgrundlage, auf denen eine Verarbeitung beruht,
entnehmen Sie dieser Datenschutzerklärung. Wenn Sie Widerspruch einlegen,
werden wir Ihre betroffenen personenbezogenen Daten nicht mehr verarbeiten, es
sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung
nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen oder die
Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).`}</p>
      <h3>{`SSL- BZW. TLS-VERSCHLÜSSELUNG`}</h3>
      <p>{`Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns
als Seitenbetreiber senden, eine SSL- bzw. TLS- Verschlüsselung. Eine
verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers
von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an
uns übermitteln, nicht von Dritten mitgelesen werden.`}</p>
      <h3>{`RECHT AUF EINSCHRÄNKUNG DER VERARBEITUNG`}</h3>
      <p>{`Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur
Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen,
haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer
personenbezogenen Daten zu verlangen. Wenn Sie einen Widerspruch nach Art. 21
Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren
Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
personenbezogenen Daten zu verlangen.`}<br parentName="p"></br>{`
`}{`Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben,
dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung
oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder
zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus
Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines
Mitgliedstaats verarbeitet werden.`}</p>
      <h3>{`WIDERSPRUCH GEGEN WERBE-E-MAILS`}</h3>
      <p>{`Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten
zur Übersendung von nicht ausdrücklich angeforderter Werbung und
Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten
behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
von Werbeinformationen, etwa durch Spam-E-Mails, vor.`}</p>
      <h3>{`DATENERFASSUNG AUF UNSERER WEBSITE`}</h3>
      <h4>{`COOKIES`}</h4>
      <p>{`Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf
Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu,
unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind
kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser
speichert.`}<br parentName="p"></br>{`
`}{`Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“.
Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben
auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es
uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.`}<br parentName="p"></br>{`
`}{`Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies
informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies
für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der
Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
kann die Funktionalität dieser Website eingeschränkt sein.`}<br parentName="p"></br>{`
`}{`Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder
zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z. B.
Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f
DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der
Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung
seiner Dienste. Soweit andere Cookies (z. B. Cookies zur Analyse Ihres
Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung
gesondert behandelt.`}</p>
      <h4>{`SERVER-LOG-DATEIEN`}</h4>
      <p>{`Der Provider der Website erhebt und speichert automatisch Informationen in so
genannten Server-Log- Dateien, die Ihr Browser automatisch an uns übermittelt. Dies
sind:`}<br parentName="p"></br>{`
`}{`Browsertyp und Browserversion, verwendetes Betriebssystem, Referrer
URL, Hostname des zugreifenden Rechners, Uhrzeit der Serveranfrage, IP-
Adresse`}<br parentName="p"></br>{`
`}{`Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
vorgenommen.`}</p>
      <p>{`Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien
Darstellung und der Optimierung seiner Website – hierzu müssen die Server-Log-
Files erfasst werden.`}</p>
      <h3>{`ANFRAGE PER E-MAIL ODER TELEFON`}</h3>
      <p>{`Wenn Sie uns per E-Mail oder Telefon kontaktieren, wird Ihre Anfrage inklusive aller
daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke
der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten
geben wir nicht ohne Ihre Einwilligung weiter.`}<br parentName="p"></br>{`
`}{`Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO,
sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur
Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen
beruht die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) und/oder
auf unseren berechtigten Interessen (Art. 6 Abs. 1 lit. f DSGVO), da wir ein
berechtigtes Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen
haben.`}<br parentName="p"></br>{`
`}{`Die uns per Kontaktanfragen übersandten Daten von Ihnen verbleiben bei uns, bis
Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder
der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener
Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere
gesetzliche Aufbewahrungsfristen – bleiben unberührt.`}</p>
      <p>{`Quelle: eRecht24`}</p>
      <h3>{`GOOGLE ANALYTICS`}</h3>
      <p>{`Diese Website nutzt den Dienst „Google Analytics“, welcher von der Google Inc. (1600 Amphitheatre Parkway Mountain View,
CA 94043, USA) angeboten wird, zur Analyse der Websitebenutzung durch Nutzer. Der Dienst verwendet „Cookies“ –
Textdateien, welche auf Ihrem Endgerät gespeichert werden. Die durch die Cookies gesammelten Informationen werden im
Regelfall an einen Google-Server in den USA gesandt und dort gespeichert.
Auf dieser Website greift die IP-Anonymisierung. Die IP-Adresse der Nutzer wird innerhalb der Mitgliedsstaaten der EU
und des Europäischen Wirtschaftsraum gekürzt. Durch diese Kürzung entfällt der Personenbezug Ihrer IP-Adresse. Im Rahmen
der Vereinbarung zur Auftragsdatenvereinbarung, welche die Websitebetreiber mit der Google Inc. geschlossen haben,
erstellt diese mithilfe der gesammelten Informationen eine Auswertung der Websitenutzung und der Websiteaktivität und
erbringt mit der Internetnutzung verbundene Dienstleistungen.`}</p>
      <p>{`Sie haben die Möglichkeit, die Speicherung des Cookies auf Ihrem Gerät zu verhindern, indem Sie in Ihrem Browser
entsprechende Einstellungen vornehmen. Es ist nicht gewährleistet, dass Sie auf alle Funktionen dieser Website ohne
Einschränkungen zugreifen können, wenn Ihr Browser keine Cookies zulässt.`}</p>
      <p>{`Weiterhin können Sie durch ein Browser-Plugin verhindern, dass die durch Cookies gesammelten Informationen (inklusive
Ihrer IP-Adresse) an die Google Inc. gesendet und von der Google Inc. genutzt werden. Hier können sie
`}<a parentName="p" {...{
          "href": "javascript:gaOptout();"
        }}>{`Google Analytics deaktivieren`}</a>{`
Hier finden Sie weitere Informationen zur Datennutzung durch die Google Inc.: `}<a parentName="p" {...{
          "href": "https://support.google.com/analytics/answer/6004245?hl=de"
        }}>{`https://support.google.com/analytics/answer/6004245?hl=de`}</a></p>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      